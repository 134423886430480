<template>
  <v-main class="login">
    <div class="px-3 mt-10">
      <ValidationObserver ref="observer">
      <v-row justify="center">
        <v-col cols="12" sm="5" class="pb-0">
          <p class="subtitle-2 font-weight-black ma-0">ログインID（メールアドレス）</p>
          <ValidationProvider v-slot="{ errors }" name="ログインID（メールアドレス）" rules="required">
            <v-text-field v-model="login_id" single-line outlined dense
                          :error-messages="errors" @change="inputLoginId"></v-text-field>
          </ValidationProvider>
        </v-col>
        <v-col cols="12" sm="5" class="pb-0">
          <p class="subtitle-2 font-weight-black ma-0">パスワード</p>
          <ValidationProvider v-slot="{ errors }" name="パスワード" rules="required">
            <v-text-field v-model="password" single-line outlined dense
                          :error-messages="errors"
                          :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                          :type="show ? 'text' : 'password'" @click:append="show = !show" @change="inputPassword">
            </v-text-field>
          </ValidationProvider>
        </v-col>
      </v-row>

      <div class="text-center mt-5">
        <v-btn rounded class="maincolor-bg default_button"
               @click.stop="login()">ログイン</v-btn>
      </div>
      </ValidationObserver>

      <v-divider class="my-5"></v-divider>

      <v-row class="caption" justify="center">
        <a @click.stop="lostPassword()">パスワードをお忘れの方</a>
      </v-row>
      <v-row class="caption" justify="center">
        <a @click.stop="newAccount()">新しいアカウントを作る</a>
      </v-row>

      <div class="text-center caption pt-10">
        <p class="">
          本アプリをご利用の際には、
          <router-link to="/info/rule">利用規約</router-link>
          をご確認ください。
        </p>
        <p class="caption" v-if="inquiry_mail">
          <v-icon>far fa-envelope</v-icon>
          <a :href="'mailto:'+inquiry_mail">お問い合わせ</a>
        </p>
      </div>
    </div>

    <v-overlay :value="loading">
      <v-progress-circular :size="80" :width="2" class="maincolor" indeterminate></v-progress-circular>
    </v-overlay>
  </v-main>
</template>

<script>
import {required} from 'vee-validate/dist/rules'
import {extend, ValidationObserver, ValidationProvider} from "vee-validate";
import {PASSWORD_INTEGRATION_STATUS, DIALOG_TYPE, IS_REGIST} from '../../utils/const';

extend('required', {
  ...required,
  message: '{_field_} は必須項目です。',
})
export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      login_id: null,
      password: "",

      loading: false,
      show: false,
      inquiry_mail: null,
    };
  },
  created() {
    this.inquiry_mail = process.env.VUE_APP_INQUIRY_MAIL;
    this.storageSessionDelete("password_integration_status");
  },
  mounted () {
    // 新規登録後に画面遷移してきた場合はモーダルを表示。
    if (this.getUrlGetParam('regist_status') === IS_REGIST.YES) {
      this.callDialog('', '登録した会員情報でログインすると、ファンコミュニティサイトにご参加いただけます。', DIALOG_TYPE.OK);
    }

    const json = this.storageSessionGet('*');
    if( !json ) {
      this.storageSessionSave(JSON.stringify({}));
    } else {
      const loginId = this.storageSessionGet("input_login_id");
      const password = this.storageSessionGet("input_password");
      if(loginId) {
        this.login_id = loginId;
      }
      if(password) {
        this.password = password;
      }
    }
  },
  methods: {
    async lostPassword() {
      const callback = encodeURIComponent(process.env.VUE_APP_BASE_URL + '/commune/login');
      this.showEcPage('/password.html', 'callback=' + callback);
    },
    async newAccount() {
      await this.$router.push({name:'commune_register_email'});
    },
    async login() {
      this.$refs.observer.validate().then(async result => {
        if (!result) {
          return;
        }
        // loadingを表示
        this.loading = true;
        // ログインIDとパスワードのチェック
        try {
          const login_req = {
            'mail'    : this.login_id,
            'password': this.password,
          };
          // ログインAPIの呼出
          const login_res = await this.apiCallCustomerPF('/commune/user/auth_with_password', login_req);
          if (login_res.password_integration_status === PASSWORD_INTEGRATION_STATUS.NOT_MATCH) {
            // password_integration_status=1 の場合はCommune 登録・ログイン選択画面（パスワード再設定画面）に遷移。
            this.storageSessionSave(JSON.stringify({password_integration_status: PASSWORD_INTEGRATION_STATUS.NOT_MATCH}));
            await this.$router.push({name:'commune_index'});
            return;
          }
        } catch(e) {
          this.loading = false;
          this.callDialog('ログインエラー', 'ログインID（メールアドレス）もしくはパスワードを確認してください。', DIALOG_TYPE.OK);
          return;
        }
        // JWT生成、CommuneへのSSOリダイレクト
        try {
          const jwt_req = {
            'mail'    : this.login_id,
          }
          // JWT生成APIの呼出
          const jwt_res = await this.apiCallCustomerPF('/commune/generate_jwt', jwt_req);
          if (!jwt_res.jwt) {
            this.loading = false;
            this.callDialog('', '時間をおいてリトライしてください。', DIALOG_TYPE.OK);
            return;
          }
          // CommuneへのSSOリダイレクト
          window.location.href = process.env.VUE_APP_COMMUNE_SSO_URL + '?jwtToken=' + jwt_res.jwt;
        } catch(e) {
          this.loading = false;
          this.callDialog('', '時間をおいてリトライしてください。', DIALOG_TYPE.OK);
        }
      });
    },
    async inputLoginId(e) {
      this.storageSessionSave(JSON.stringify({
        "input_login_id"  : e ,
      }));
    },
    async inputPassword(e) {
      this.storageSessionSave(JSON.stringify({
        "input_password"  : e ,
      }));
    }
  }
};
</script>

<style scoped>
.fontsize11> >>label {
  font-size: 11px;
}

a {
  color: #333333;
}
</style>
